<template>
  <div style="background: #fafafa">
    <div class="container__img__aboutUs">
      <img
        alt="banner contact us"
        class="image-contact-us-banner"
        src="https://i.ytimg.com/vi/XV2g-SSa-2c/maxresdefault.jpg"
      />

      <div class="container__img__aboutUs__info">
        <h1 class="text-uppercase text-title-contactus">Contáctanos</h1>
      </div>
    </div>
    <div class="container-fluid size py-6-contact-us">
      <h2>¡Estamos aquí para escucharte!</h2>
      <div class="form mt-4">
        <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-6">
            <base-input
              v-if="isAuthenticated"
              v-model="getProfile.name"
              class="hidden-name"
              label="Nombre con login"
              placeholder="Nombre"
              type="text"
            >
            </base-input>

            <base-input
              v-if="!isAuthenticated"
              v-model="dataUser.name"
              label="Nombre"
              placeholder="Nombre"
              type="text"
            >
            </base-input>

            <base-input
              v-if="isAuthenticated"
              v-model="getProfile.email"
              class="hidden-name"
              label="Correo"
              placeholder="Ingresa tu correo"
              type="email"
            >
            </base-input>

            <base-input
              v-if="!isAuthenticated"
              v-model="dataUser.email"
              label="Correo"
              placeholder="Ingresa tu correo"
              type="text"
            >
            </base-input>

            <textarea
              v-model="dataUser.content"
              class="description-help"
              placeholder="Describe cómo te podemos ayudar."
              rows="10"
            >
            </textarea>
            <base-button
              :loading="isLoading"
              class="btn btn-maquillate mt-3"
              @click="contactProfile"
            >
              Enviar
            </base-button>
          </div>
          <div class="col-12">
            <p></p>
            <p class="mt-3" style="color: black">
              Se puede comunicar con nuestro servicio al cliente de lunes a
              viernes de 9 am a 5 pm EST envíando un correo a
              <a
                style="color: #b7227e; text-decoration: none"
                href="mailto:ayuda@maquillate.com"
                >ayuda@maquillate.com</a
              >
              .
            </p>
          </div>
        </div>
        <!-- Message help-->
      </div>
      <!-- Message help end-->
    </div>
  </div>
</template>
<script>
import { USER_CONTACT } from '@/store/actions/user';
import { mapGetters } from 'vuex';
import validator from 'validator';
import BaseButton from '../../../../components/BaseButton';

export default {
  name: 'ContactUs',
  components: {
    BaseButton,
  },
  data() {
    return {
      isLoading: false,
      dataUser: {
        email: null,
        name: null,
        content: null,
        id_user: null,
      },
    };
  },
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated']),
  },

  created() {},
  methods: {
    ErrorValidation(data) {
      const errors = [];

      if (!data.name) {
        errors.push('Debes ingresar un nombre');
      }

      if (!data.email) {
        errors.push('Debes ingresar un correo');
      } else if (!validator.default.isEmail(data.email)) {
        errors.push('El formato del correo esta incorrecto');
      }

      if (!data.content) {
        errors.push('Debes ingresar un mensaje');
      }

      return errors;
    },

    contactProfile() {
      let data = {
        name: this.dataUser?.name ?? this.getProfile?.name ?? '',
        email: this.dataUser?.email ?? this.getProfile?.email ?? '',
        content: this.dataUser?.content ?? '',
      };

      let errors = this.ErrorValidation(data);

      if (errors.length) {
        this.notify(errors.join('</br>'), 'danger');
        return;
      }
      this.isLoading = true;
      this.$store
        .dispatch(USER_CONTACT, data)
        .then(() => {
          this.dataUser.content = '';
          this.dataUser.email = '';
          this.dataUser.name = '';
          this.isLoading = false;

          this.notify('Se ha enviado el correo con éxito', 'success');
        })
        .catch(() => {
          this.dataUser.content = '';
          this.dataUser.email = '';
          this.dataUser.name = '';
          this.isLoading = false;
          this.notify('Tenemos problemas para enviar el correo', 'danger');
        });
    },

    notify(message, type) {
      this.$notify({
        type: type,
        verticalAlign: 'top',
        horizontalAlign: 'right',
        message: message,
      });
    },
  },
};
</script>

<style scoped>
::placeholder {
  color: #232323 !important;
}

.hidden-name {
  display: none;
}
.container__img__aboutUs__info {
  height: 200px !important;
  background-color: rgba(0, 0, 0, 0.9) !important;
  padding-top: 60px;
}
.image-contact-us-banner {
  height: 200px;
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

.text-title-contactus {
  font-size: 2.5rem !important;
}

@media screen and (max-width: 576px) {
  .image-contact-us-banner {
    height: 150px;
    width: 100%;
    max-height: 150px;
    object-fit: cover;
    padding-top: 60px;
  }
  .container__img__aboutUs__info {
    height: 150px !important;
    background-color: rgba(0, 0, 0, 0.9) !important;
    padding-top: 60px;
  }
  .text-title-contactus {
    font-size: 1.5rem !important;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .image-contact-us-banner {
    height: 150px;
    width: 100%;
    max-height: 150px;
    object-fit: cover;
    padding-top: 60px;
  }
  .container__img__aboutUs__info {
    height: 150px !important;
    background-color: rgba(0, 0, 0, 0.9) !important;
    padding-top: 50px;
  }

  .text-title-contactus {
    font-size: 2rem !important;
  }
}
</style>
